import React from 'react';
import classnames from 'classnames';
import ReactModal from 'react-modal';
import styled from 'styled-components';

ReactModal.setAppElement('body');

const ModalContainer = styled(ReactModal)`
  width: 100%;
  height: 100%;
  position: relative;
  transition: opacity 0.25s ease-in-out;
  inset: 0;
  padding: 0;

  #container-inner {
    display: flex;
    height: 100%;
    flex: 1;
    flex-flow: column;
    flex-direction: column-reverse;
  }

  > #close-btn {
    font-size: 1.2em;
    color: #000;
    width: 1.5em;
    height: 1.5em;
    border-radius: 50%;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 100;
    margin-left: 1em;
    position: absolute;
    right: 10em;

    &:hover {
      cursor: pointer;
    }
  }
  #header {
    flex: 0 1 auto;
    order: 2;
  }

  #body {
    flex: 1 1 auto;
    order: 1;
  }

  .hide {
    opacity: 0;
    pointer-events: none;
  }

  #container-inner.with-title {
    background-color: white;
    padding: 1em 1.5em 1.5em 1.5em;
    border-radius: 1em;

    #header {
      width: 100%;
      display: flex;
      order: unset;
      justify-content: space-between;
      margin-bottom: 10px;
      // border-bottom: 1px solid #ebebeb;
      font-weight: bold;
      font-variant: small-caps;
    }
  }

  @media screen and (max-width: 768px) and (orientation: portrait) {
    #container-inner {
      flex-direction: column;
    }

    #header {
      order: 1;
      display: flex;
      align-self: flex-end;
      margin-bottom: 1em;
    }

    #close-btn {
      margin-left: 0;
    }
  }
`;

interface IModalProps extends ReactModal.Props {
  displayClose: boolean;
  title?: string;
}

function Modal({ title, displayClose = false, children, ...props }: IModalProps) {
  return (
    <ModalContainer
      {...props}
      shouldCloseOnOverlayClick={false}
      overlayClassName="modal-container-overlay"
    >
      <div
        id="container-inner"
        className={classnames('wl-template', {
          'with-title': !!title,
        })}
      >
        {(title || displayClose) && (
          <div id="header">
            {title && <span id="title">{title}</span>}
            {displayClose && (
              <span
                id="close-btn"
                role="button"
                tabIndex={0}
                onClick={props.onRequestClose}
                onKeyUp={() => {}}
              >
                x
              </span>
            )}
          </div>
        )}

        <div id="body">{children}</div>
      </div>
    </ModalContainer>
  );
}
Modal.defaultProps = { title: undefined };

export default Modal;
