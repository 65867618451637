import React, { useCallback, useRef } from 'react';
import Flatpickr from 'react-flatpickr';
import styled from 'styled-components';
import { ControllerRenderProps } from 'react-hook-form/dist/types/controller';
import { IconCalendar } from '@common/icons';
import moment from 'moment-timezone';
import { getLastAvailableDate } from '@utils';

const Icon = styled.span`
  width: 1.6em;
  height: 1.6em;
  position: absolute;
  top: 50%;
  left: 0.8em;
  transform: translate3d(0, -50%, 0);
`;

interface DatePickerProps extends ControllerRenderProps {
  dateWindow: number | null;
  hotelTimezone: string;
}

function DatePicker(controllerProps: DatePickerProps) {
  const { onChange, value, dateWindow, hotelTimezone } = controllerProps;
  const flatpickr = useRef(null);
  const onDateChange = useCallback(([date]: any) => onChange(date), [onChange]);
  const today = moment.tz(hotelTimezone);

  const maxDate = getLastAvailableDate(today, dateWindow || 6);
  return (
    <div id="DatePickerContainer">
      <div id="picker">
        <Flatpickr
          options={{
            minDate: 'today',
            maxDate: maxDate.toDate(),
            defaultDate: value,
            dateFormat: 'l, F d',
            disableMobile: true,
            appendTo: flatpickr.current || undefined,
          }}
          onReady={onDateChange}
          onChange={onDateChange}
          className="flatpickr-calendar"
          render={({ defaultValue }, ref) => <input defaultValue={defaultValue} ref={ref} />}
        />
        <div id="flatpickr" ref={flatpickr} />
        <div id="handles">&lt;&nbsp;&nbsp;&gt;</div>
        <Icon>
          <IconCalendar />
        </Icon>
      </div>
    </div>
  );
}

export default DatePicker;
