import React from 'react';
import { marked } from 'marked';
import classnames from 'classnames';
import { sanitize } from '@utils';

interface IMarkdown {
  id?: string;
  className?: string | string[];
  text: string;
}

function Markdown({ id, className = '', text }: IMarkdown) {
  return (
    <div
      id={id}
      className={classnames(className)}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: sanitize(marked(text)),
      }}
    />
  );
}
Markdown.defaultProps = { id: undefined, className: undefined };

export default Markdown;
