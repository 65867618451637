import React from 'react';
import axios from 'axios';
import Homepage from '@components/Homepage/Homepage';
import { API_URL, REDIRECT_404_URL } from '@constants';
import { getServerSideHotelDetails, shouldRedirect } from '@utils';
import { IConfig, IMicrositeImages, IProductCategory } from '../../../types';

export const getServerSideProps = async (context) => {
  const details = await getServerSideHotelDetails(context);
  const redirect = shouldRedirect(details, context);

  if (redirect) {
    return redirect;
  }

  const { hotelId, config, images, hotelTimezone, bookingWindow } = details;
  const { data: categories = [] } = await axios.get(
    `${API_URL}/api/hotels/${hotelId}/product_categories`,
  );

  if (!hotelId) {
    return {
      redirect: {
        destination: REDIRECT_404_URL,
        permanent: false,
      },
    };
  }

  return {
    props: { config, images, productCategories: categories, hotelTimezone, bookingWindow },
  };
};

interface IIndexProps {
  config: IConfig;
  images: IMicrositeImages | undefined;
  productCategories: IProductCategory[];
  hotelTimezone: string;
  bookingWindow: number | null;
}

function Index({ config, productCategories, images, hotelTimezone, bookingWindow }: IIndexProps) {
  return (
    <Homepage
      config={config}
      categories={productCategories}
      images={images}
      hotelTimezone={hotelTimezone}
      bookingWindow={bookingWindow}
    />
  );
}

export default Index;
