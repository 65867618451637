import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { useRouter } from 'next/router';
import PersonalizedMessage from '@common/PersonalizedMessage';
import Modal from '@common/Modal';
import DateModal from '@common/DateModal';
import { buildQueryParams } from '@utils';
import { useAppContext } from '@common/Context';
import ProductCategories from './ProductCategories';
import Form from './Form';
import { IConfig, IMicrositeImages, IProductCategory } from '../../types';

interface IHomepageProps {
  config: IConfig;
  categories: IProductCategory[];
  images?: IMicrositeImages;
  hotelTimezone: string;
  bookingWindow: number | null;
}

interface IHomeWithCategoriesProps {
  config: IConfig;
  categories: IProductCategory[];
  setSelectedCategory: Function;
  setDisplayDateModal: Function;
}

interface IHomeWithoutCategoriesProps {
  config: IConfig;
  images?: IMicrositeImages;
  hotelTimezone: string;
  bookingWindow: number | null;
}

function HomeWithCategories({
  config,
  categories,
  setSelectedCategory,
  setDisplayDateModal,
}: IHomeWithCategoriesProps) {
  const router = useRouter();
  return (
    <div id="home-with-categories">
      <ProductCategories
        config={config}
        categories={categories}
        onCategoryConfirmation={(productCategory) => {
          setSelectedCategory(productCategory);

          if (!router.query.confirmation && !router.query.date) {
            setDisplayDateModal(true);
          } else if (config?.home.confirmation.type === 'date') {
            router.push(
              `/availability?${buildQueryParams(
                {
                  date: router.query.date,
                  category: productCategory?.slug,
                  preview: router.query.preview,
                },
                {},
              )}`,
            );
          } else {
            router.push(
              `/availability?${buildQueryParams(
                {
                  confirmation: router.query.confirmation,
                  category: productCategory?.slug,
                  preview: router.query.preview,
                },
                {},
              )}`,
            );
          }
        }}
      />
    </div>
  );
}

function HomeWithoutCategories({
  config,
  images,
  hotelTimezone,
  bookingWindow,
}: IHomeWithoutCategoriesProps) {
  const router = useRouter();
  const asDatePicker = config?.home.confirmation.type === 'date';
  return (
    <div id="home-without-categories">
      {images?.hero && (
        <div
          id="background"
          style={{
            backgroundImage: `url(${images.hero})`,
          }}
        />
      )}
      <div id="reservation">
        <div id="heading">
          {config?.home.heading
            ? config.home.heading
            : 'Reserve your cabana or day bed prior to your arrival'}
        </div>
        <div id="form-wrapper">
          <Form
            hotelTimezone={hotelTimezone}
            config={config}
            asDatePicker={asDatePicker}
            label={config?.home.confirmation.label}
            bookingWindow={bookingWindow}
            onSubmit={(data) => {
              if (asDatePicker) {
                const date = data.date as Date;
                router.push(
                  `/availability?${buildQueryParams({ preview: router.query.preview, date }, {})}`,
                );
              } else {
                router.push(
                  `/availability?${buildQueryParams(
                    {
                      preview: router.query.preview,
                      confirmation: data[config.home.confirmation.name],
                    },
                    {},
                  )}`,
                );
              }
            }}
          />
          {config?.hotelDLP && (
            <div id="info">
              <span>
                Not an overnight guest and want access for the day? Visit
                <a
                  id="dlp"
                  href={config.hotelDLP || 'https://www.resortpass.com'}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  ResortPass.com
                </a>
                .
              </span>
            </div>
          )}
        </div>
        <PersonalizedMessage config={config} v2={config?.home?.confirmation.type === 'date'} />
      </div>
    </div>
  );
}
HomeWithoutCategories.defaultProps = { images: undefined };

function Homepage({ config, categories, images, hotelTimezone, bookingWindow }: IHomepageProps) {
  const { actions } = useAppContext();
  const [displayDateModal, setDisplayDateModal] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<IProductCategory>();

  useEffect(() => {
    actions.setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div id="HomepageContainer">
      <div
        id="wrapper"
        className={classnames({
          v1: config?.home?.confirmation.type !== 'date',
          v2: config?.home?.confirmation.type === 'date',
        })}
      >
        {categories.length > 1 ? (
          <HomeWithCategories
            config={config}
            categories={categories}
            setSelectedCategory={setSelectedCategory}
            setDisplayDateModal={setDisplayDateModal}
          />
        ) : (
          <HomeWithoutCategories
            config={config}
            images={images}
            hotelTimezone={hotelTimezone}
            bookingWindow={bookingWindow}
          />
        )}
      </div>
      <Modal
        onRequestClose={() => setDisplayDateModal(false)}
        isOpen={displayDateModal}
        displayClose={false}
      >
        <DateModal
          config={config}
          selectedCategory={selectedCategory}
          closeModal={() => setDisplayDateModal(false)}
          hotelTimezone={hotelTimezone}
          bookingWindow={bookingWindow}
        />
      </Modal>
    </div>
  );
}
Homepage.defaultProps = { images: undefined };

export default Homepage;
