import React from 'react';
import { useRouter } from 'next/router';
import { buildQueryParams } from '@utils';
import PersonalizedMessage from './PersonalizedMessage';
import Form from '../Homepage/Form';
import { IConfig, IProductCategory } from '../../types';

interface IDateModalProps {
  config: IConfig;
  closeModal: () => void;
  selectedCategory?: IProductCategory;
  hotelTimezone: string;
  bookingWindow: number | null;
}

function DateModal({
  config,
  closeModal,
  selectedCategory,
  hotelTimezone,
  bookingWindow,
}: IDateModalProps) {
  const router = useRouter();
  const asDatePicker = config?.home.confirmation.type === 'date';
  return (
    <div id="DateModalContainer">
      <div id="identifier-confirmation-modal">
        <div id="title" className="section">
          <div id="heading-wrapper">
            <div id="heading">{config?.home.confirmation.label as string}</div>
          </div>
          <div id="close-wrapper" className="">
            <div id="close" className="">
              <a
                role="button"
                id="close-btn"
                onClick={() => closeModal()}
                onKeyUp={() => closeModal()}
                tabIndex={0}
              >
                x
              </a>
            </div>
          </div>
        </div>
        <div id="form-wrapper">
          <Form
            bookingWindow={bookingWindow}
            hotelTimezone={hotelTimezone}
            config={config}
            asDatePicker={asDatePicker}
            onSubmit={(data) => {
              if (asDatePicker) {
                const date = data.date as Date;
                router.push(
                  `/availability?${buildQueryParams(
                    {
                      preview: router.query.preview,
                      date,
                      category: selectedCategory?.slug,
                    },
                    {},
                  )}`,
                );
              } else {
                router.push(
                  `/availability?${buildQueryParams(
                    {
                      preview: router.query.preview,
                      confirmation: data[config.home.confirmation.name as string],
                      category: selectedCategory?.slug,
                    },
                    {},
                  )}`,
                );
              }
            }}
          />
          {config.hotelDLP && (
            <div id="info">
              <span>
                Not an overnight guest and want access for the day? Visit{' '}
                <a
                  id="dlp"
                  href={config.hotelDLP || 'https://www.resortpass.com'}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  ResortPass.com
                </a>
                .
              </span>
            </div>
          )}
          <PersonalizedMessage config={config} v2={false} />
        </div>
      </div>
    </div>
  );
}

DateModal.defaultProps = { selectedCategory: {} };

export default DateModal;
