import React from 'react';
import classnames from 'classnames';
import Image from '@common/Image';
import type { IConfig, IProductCategory } from '../../types';

interface IProdCategoriesProps {
  config: IConfig;
  categories: IProductCategory[];
  onCategoryConfirmation: (productCategory: IProductCategory) => void;
}

function ProductCategories({ config, categories, onCategoryConfirmation }: IProdCategoriesProps) {
  const onCategoryClick = async (productCategory: IProductCategory) => {
    if (productCategory.category_type !== 1) {
      onCategoryConfirmation(productCategory);
    }
  };

  return (
    <div id="ProductCategoriesContainer">
      <h2 id="heading-text">{config.home.heading}</h2>
      <div
        id="product-categories"
        className={classnames('cols-3-md', {
          'cols-3': categories.length <= 3,
        })}
      >
        {categories.map((productCategory, index: number) => (
          <a
            key={productCategory.name}
            role="button"
            {...(productCategory.category_type === 1
              ? { target: '_blank', href: productCategory.link }
              : {})}
            tabIndex={index}
            onClick={() => onCategoryClick(productCategory)}
            onKeyUp={() => onCategoryClick(productCategory)}
            className="product-category"
          >
            <div className="image-container">
              <Image
                src={
                  productCategory.image.url ||
                  'https://dummyimage.com/400x360/ccc/ffffff&text=No+image+available'
                }
                alt=""
              />
            </div>
            <div className="title-container">
              <div className="title">{productCategory.name}</div>
            </div>
          </a>
        ))}
      </div>
    </div>
  );
}

export default ProductCategories;
