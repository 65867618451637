import React from 'react';

interface IImageProps {
  src?: string;
  className?: string;
  alt?: string;
  id?: string;
}

const FALLBACK_IMG = 'https://dummyimage.com/400x360/ccc/ffffff&text=No+image+available';

function Image({ src, className, id, alt }: IImageProps) {
  return (
    // eslint-disable-next-line @next/next/no-img-element
    <img
      src={src || FALLBACK_IMG}
      id={id}
      className={className}
      onError={(e) => {
        e.currentTarget.src = FALLBACK_IMG;
      }}
      alt={alt}
    />
  );
}
Image.defaultProps = { src: undefined, className: undefined, alt: undefined, id: undefined };

export default Image;
