import React, { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import moment from 'moment-timezone';
import DatePicker from '@common/DatePicker';
import Button from '@common/Button';
import { IConfig } from '../../types';

interface IFormProps {
  date?: string;
  config: IConfig;
  asDatePicker: boolean;
  hotelTimezone: string;
  bookingWindow: number | null;
  label?: string;
  onChange?: (data: Date) => void;
  onSubmit?: (data: { [p: string]: string | Date }) => void;
}

function Form({
  date,
  config,
  asDatePicker,
  label,
  onChange,
  onSubmit,
  hotelTimezone,
  bookingWindow,
}: IFormProps) {
  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { isValid },
  } = useForm({ mode: 'all' });

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === 'date' && onChange) {
        onChange(value as Date);
      }
    });
    return () => subscription.unsubscribe();
  }, [onChange, watch]);

  const onFormSubmit = (data) => {
    if (onSubmit) {
      onSubmit(data);
    }
  };

  return (
    <form id="form" onSubmit={handleSubmit(onFormSubmit)}>
      <label id="label" htmlFor="confirmation">
        {label && <span id="label-content">{label}</span>}
        {asDatePicker ? (
          <Controller
            name="date"
            control={control}
            defaultValue={
              date
                ? moment.tz(new Date(date), hotelTimezone).toDate()
                : moment.tz(new Date(), hotelTimezone).toDate()
            }
            render={({ field }) => (
              <DatePicker {...field} dateWindow={bookingWindow} hotelTimezone={hotelTimezone} />
            )}
          />
        ) : (
          <input
            type="text"
            id="input"
            placeholder={config.home.confirmation.placeholder}
            {...register(config.home.confirmation.name, {
              required: config.home.confirmation.required,
              pattern: config.home.confirmation.regex
                ? new RegExp(config.home.confirmation.regex)
                : undefined,
            })}
          />
        )}
        {onSubmit && (
          <Button id="submitBtn" className="wl-btn-primary" disabled={!isValid}>
            Submit
          </Button>
        )}
      </label>
    </form>
  );
}
Form.defaultProps = { date: undefined, label: undefined, onChange: undefined, onSubmit: undefined };

export default Form;
