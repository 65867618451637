import React from 'react';
import styled from 'styled-components';
import { IconInfo } from '@common/icons';
import { IConfig } from '../../types';
import Markdown from './Markdown';

const PersonalizedMessageContainer = styled.div<{ v2: boolean }>`
  width: 100%;
  padding: ${(props) => (props.v2 ? '0' : '0 2em')};

  .hidden {
    display: none !important;
  }

  @media screen and (max-width: 768px) {
    :host {
      display: flex;
      padding: 0 !important;
    }
  }
`;

interface IPersonalizedMessage {
  config: IConfig;
  v2: boolean;
}

function PersonalizedMessage({ config, v2 }: IPersonalizedMessage) {
  return config.home.personalizedMessage ? (
    <PersonalizedMessageContainer v2={v2}>
      <div id="personalized-message">
        <div id="icon">
          <IconInfo />
        </div>
        <Markdown id="message" text={config.home.personalizedMessage} />
      </div>
    </PersonalizedMessageContainer>
  ) : null;
}

export default PersonalizedMessage;
